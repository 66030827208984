.photoRonde {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    box-shadow: 3px 4px 7px 0px rgba(50, 50, 50, 0.64);
    margin-top: 40px;
}

.cardDiscours {
    background-color: white;
    border-radius: 5px;
    box-shadow: 3px 4px 7px 0px rgba(50, 50, 50, 0.64);
    padding: 20px;
    max-width: 600px;
    margin:40px;
    font-family: 'Caveat';
    font-size: 1.6rem;
}

@media (max-width: 575.98px){
    .cardDiscours {
        margin:5px;
    }
}

.texteADroite {
    text-align: right;
}

.guillemetOuvre, .guillemetFerme {
    font-size: 10rem;
    padding: 0;
    color:rgba(146, 48, 136, 0.8);
    margin-bottom: -130px; 
}

.guillemetOuvre {
    margin-left: -20px;
    margin-top:-50px;
    
}

.guillemetFerme {
    margin-right: 30px;
    margin-top:-60px;
}

.imgOffre {
    width: 100%;
    max-width: 1000px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.h2Style {
    font-size: 2rem;
    font-weight: 800;
    color: #EB5F7A;
    height: 40px;
    text-align: center;
}

.espaceMarge {
    margin-top: 80px;
}