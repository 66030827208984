.qualiteFond {
    width: 300px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 3px 4px 7px 0px rgba(50, 50, 50, 0.64);
    margin-bottom: 50px;
    padding-bottom: 20px;
    margin-left:20px;
    margin-right: 20px;
}

.qualiteImage {
    position: absolute;
    background-color:rgb(146, 48, 136);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-left: 140px;
    margin-top: -30px;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

@media (max-width: 575.98px) {
    .qualiteFond {
        margin-left:0px;
        margin-right: 0px;
    }
    .qualiteImage {
        margin-left: 120px;
    }
}

.qualiteIcon {
    color: #EB5F7A;
}

.qualiteTitre {
    text-align: center;
    padding-top: 20px;
    font-size: 1.2rem;
    color: #EB5F7A;
    font-weight: 600;
}

.qualiteContenu {
    /* padding-top: 50px; */
    padding-left: 20px;
    padding-right: 10px;
}