.reaBoiteImage{
    padding: 30px 50px;
}
.reaImage{
    width: 100%;
    box-shadow: 3px 4px 7px 0px rgba(50, 50, 50, 0.64);
}
.reaBoiteContenu{
    padding: 30px 0px;
}
.reaTitre{
    font-weight: 600;
    font-size: 1.2rem;
    color: #923088;
    margin-top: -2px;
}
.reaCaracteristiques{
    font-size: 0.9rem;
    color: #EE7F73;
    font-style: italic;
    margin-top: -15px;
}
.reaDescription{
    padding-top: 10px;
}
.reaDescription p{
    margin-top: -0.5rem;
}
.reaLien{
    font-size: 0.9rem;
}

@media (max-width: 959.99px){
    .reaBoiteImage{
        padding: 20px 5px 20px 5px;
    }
    .reaBoiteContenu{
        padding: 0px 5px 20px 5px;
    }
    .reaTitre{
        text-align: center;
    }
    .reaCaracteristiques{
        text-align: center;
    }
    .reaLien{
        font-size: 0.9rem;
        text-align: center;
    }
}