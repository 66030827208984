.cardFond{
    width: 300px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 3px 4px 7px 0px rgba(50, 50, 50, 0.64);
    margin-bottom: 50px;
}

.cardImage{
    position: absolute;
    background-color:rgb(245, 195, 59);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-left: 120px;
    margin-top: -280px;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.cardIcon{
    color: rgb(235, 95, 122);
}

.cardContenu{
    height: 150px;
    text-align: center;
    padding-top: 50px;
}

.lienTel{
    text-decoration: none;
    font-size: 1.6rem;
    color: #414042;
    font-weight: 800;
}

.lienTel:hover{
    color: rgb(235, 95, 122);
}