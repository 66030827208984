.formulaire {
    margin-top: 60px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 3px 4px 7px 0px rgba(50, 50, 50, 0.64);
    padding: 40px 20px 20px 20px;
    text-align: center;
}

.logoMail{
    position: absolute;
    background-color:rgb(245, 195, 59);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-top: -434px;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

@media (max-width: 575.98px){
    .formulaire{
        width: 260px;
    }
    .logoMail{
        margin-left: 120px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px){
    .formulaire{
        width: 516px;
    }
    .logoMail{
        margin-left: 248px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px){
    .formulaire{
        width: 708px;
    }
    .logoMail{
        margin-left: 344px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px){
    .formulaire{
        width: 932px;
    }
    .logoMail{
        margin-left: 456px;
    }
}

@media (min-width: 1200px){
    .formulaire{
        width: 1140px;
    }
    .logoMail{
        margin-left: 560px;
    }
}

.boutonSubmit {
    margin-top: 20px;
}