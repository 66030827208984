.cardProduitFond {
    width: 100%;
    background-color: white;
    border-radius: 5px;
    box-shadow: 3px 4px 7px 0px rgba(50, 50, 50, 0.64);
    margin-bottom: 50px;
}

.cardProduitTitre {
    background-color: #EB5F7A;
    font-size: 1.4rem;
    font-weight: 600;
    color: white;
    text-align: center;
    padding: 10px 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.cardProduitContenu {
    padding: 0 20px;
}

.cardProduitSousTitre {
    color: #923088;
    font-weight: 600;
    margin-left: 10px;
    font-size: 1.1rem;
}

.cardProduitListe {
    list-style-type: none;
    margin-left: -40px;
    margin-top: -2px;
}

.puceTrianglePeche::before{
    content: "►";
    margin-right: 10px;
    color: #EE7F73;
}

.puceTrianglePeche {
    margin-bottom: 20px;
}

.cardProduitPrix {
    /* background-color: #EB5F7A; */
    font-size: 1.6rem;
    font-weight: 600;
    color: #EB5F7A;
    text-align: center;
    padding: 10px 0;
}