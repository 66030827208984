.barreNavigation{
    background-color: #ffffff;
    padding: 10px;
    display: flex;
    align-items: center;
    position: sticky;
    top:0;
    z-index: 10;
}

.avecBordure{
    box-shadow: 0px 4px 2px -2px #923088;
}
.sansBordure{
    box-shadow: none;
}

.logo {
    width: 100%;
    max-width: 400px;
}