.bouton{
    padding: 10px 20px;
    text-decoration: none;
    color: #414042;
    display: flex;
    align-items: center;
}

#triangle{
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
}

.violet {
    border-left: 40px solid rgba(146, 48, 136, 0.8);
}

.rose {
    border-left: 40px solid rgba(231, 48, 125, 0.8);
}

.peche {
    border-left: 40px solid rgba(238, 127, 115, 0.8);
}

.jaune {
    border-left: 40px solid rgba(245, 195, 59, 0.8);
}

.bouton:hover .violet, .bouton:hover .rose , .bouton:hover .peche, .bouton:hover .jaune, .boutonActif .violet, .boutonActif .rose , .boutonActif .peche, .boutonActif .jaune{
    transform-origin: center center;
    transform: rotate(-0.25turn);
    z-index: 1;
}

.libelle {
    font-weight: 600;
    font-size: 1.2rem;
    margin-left: -20px;
    z-index: 2;
}

.boutonActif .libelle{
    border-bottom: 3px solid;
}

.boutonActif .lib-violet{
    border-bottom-color: rgba(146, 48, 136, 0.8);
}

.boutonActif .lib-rose{
    border-bottom-color: rgba(231, 48, 125, 0.8);
}

.boutonActif .lib-peche{
    border-bottom-color: rgba(238, 127, 115, 0.8);
}

.boutonActif .lib-jaune{
    border-bottom-color: rgba(245, 195, 59, 0.8);
}