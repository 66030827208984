body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #414042;
}

.contenu{
    width: auto;
}

.fondDiamant {
    background-image: url('/fondDiamant.png');
    background-size: cover;
    background-attachment: fixed;
    padding-bottom: 100px;
    padding-left: 10px;
    padding-right: 10px;
}

.paddingMoins{
    padding-top: 20px;
}

.paddingPlus{
    padding-top: 60px;
}

.espaceBas{
    height: 200px;
}