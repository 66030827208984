.conteneur {
    /* width: 100%; */
    min-height: 100px;
    padding-bottom: 20px;
    background-color: rgb(235, 95, 122);
}

.triangle {
    width: 0;
    height: 0;
    border-right: 100px solid transparent;
    border-left: 100px solid transparent;
}

.violet {
    border-top: 100px solid rgba(146, 48, 136, 0.8);
}

.rose {
    border-top: 100px solid rgba(231, 48, 125, 0.8);
}

.peche {
    border-top: 100px solid rgba(238, 127, 115, 0.8);
}

.jaune {
    border-top: 100px solid rgba(245, 195, 59, 0.8);
}

.orange {
    border-top: 100px solid rgba(243, 168, 92, 0.8);
}

h1{
    margin-left: 20%;
    margin-top:-60px;
    color: white;
    font-weight: 800;
}

@media (max-width: 575.98px) {
    h1 {
        margin-left: 10%;
        margin-top:-65px;
    }
}
